import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classnames from 'classnames';

import useTapVerb from '../components/utils/useTapVerb';

import styles from './DetailImage.module.scss';
import Loader from './Loader';
import AlertIcon from '../images/icons/alert-triangle.inline.svg';

function DetailImage({ fluidImage, active }) {
    const [entering, setEntering] = useState(false);
    const [entered, setEntered] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const loadingTimer = useRef();
    const tapVerb = useTapVerb();
    const duration = 150;

    function handleStartLoad() {
        setEntering(true);

        loadingTimer.current = setTimeout(() => {
            setIsLoading(true);
        }, 1200);
    }

    function handleLoad() {
        clearTimeout(loadingTimer.current);
        setIsLoading(false);
        setHasLoaded(true);
    }

    function handleError() {
        clearTimeout(loadingTimer.current);
        setHasError(true);
        setIsLoading(false);
    }

    function handleTryAgainClick() {
        setIsLoading(true);
        setHasError(false);
    }

    // Handle fading of image
    useEffect(() => {
        let raf;
        let timer;

        function cleanup() {
            window.cancelAnimationFrame(raf);
            clearTimeout(timer);
        }

        if (active) {
            raf = window.requestAnimationFrame(() => {
                setEntered(true);
            });
            return cleanup;
        }

        setEntered(false);

        if (!entered && entering) {
            // Wait for css transition to finish
            timer = setTimeout(() => {
                setEntering(false);
            }, duration);
        }

        return cleanup;
    }, [hasLoaded, active, entering, entered]);

    let loader;
    if (isLoading && active) {
        loader = (
            <div className={styles.loaderContainer}>
                <Loader />
            </div>
        );
    }

    if (hasError && active && !isLoading) {
        return (
            <button className={styles.alertMessageButton} onClick={handleTryAgainClick}>
                <div className={styles.alertMessageInner}>
                    <div className={styles.alertMessageInnerInner}>
                        <span className={styles.alertIcon}><AlertIcon /></span>
                        <p>Shoot! Something went wrong while loading this image. {tapVerb || 'Tap'} to try again.</p>
                    </div>
                </div>
            </button>
        );
    }

    if (!hasLoaded && !active) {
        return null;
    }

    const klass = classnames(styles.image, {
        [styles.entering]: active || entering || hasLoaded,
        [styles.entered]: entered
    });

    return (
        <Fragment>
            {loader}
            <Img
                onStartLoad={handleStartLoad}
                onLoad={handleLoad}
                onError={handleError}
                className={klass}
                fluid={fluidImage}
                fadeInDuration={duration}
                style={{ maxHeight: '100%', height: '100%' }}
                imgStyle={{ objectFit: 'contain', marginBottom: 0 }}
            />
        </Fragment>
    );
}

DetailImage.propTypes = {
    fluidImage: PropTypes.object,
    active: PropTypes.bool
};

export default DetailImage;
