import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Carousel from '../components/Carousel';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import DetailHeader from '../components/DetailHeader';
import DetailImage from '../components/DetailImage';
import DetailFooter from '../components/DetailFooter';
import styles from './SingleHouse.module.scss';

function SingleHouseTemplate({ location, data }) {
    const house = data.housesJson;
    const [activeIndex, setActiveIndex] = useState(0);

    function handleIndexChange(index) {
        setActiveIndex(index);
    }

    function renderCarousel() {
        const items = house.fluidImages.map((obj, i) => {
            return (
                <DetailImage
                    fluidImage={obj.childImageSharp.medium}
                    key={i}
                    active={i === activeIndex}
                />
            );
        });

        return (
            <Carousel
                initialIndex={activeIndex}
                items={items}
                onIndexChange={handleIndexChange}
            />
        );
    }

    return (
        <Layout location={location} className={styles.container} style={{ padding: 0, maxWidth: 'none' }}>
            <Seo title={house.address} />
            <DetailHeader house={house} />
            {renderCarousel()}
            <DetailFooter
                house={house}
                itemIndex={activeIndex}
                itemCount={house.fluidImages.length}
            />
        </Layout>
    );
}

SingleHouseTemplate.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object
};

export default SingleHouseTemplate;

export const query = graphql`
    query SingleHouseTemplateQuery($id: String!, $idType: String!) {
        site {
            siteMetadata {
                title
                author
                siteUrl
            }
        }
        housesJson(id: { eq: $id }, idType: { eq: $idType }) {
            id
            idType
            source
            listed
            address
            beds
            price
            pros
            cons
            baths
            sqft
            ...House_details
        }
    }
`;
