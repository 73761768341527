import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { clamp } from '../utils/index';

import ChevronLeftIcon from '../images/icons/chevron-left.inline.svg';
import ChevronRightIcon from '../images/icons/chevron-right.inline.svg';

import styles from './Carousel.module.scss';

let xDown = null;
let yDown = null;

export default class Carousel extends Component {
    static propTypes = {
        items: PropTypes.array,
        onIndexChange: PropTypes.func,
        className: PropTypes.string,
        initialIndex: PropTypes.number
    };

    static defaultProps = {
        initialIndex: 0,
        onIndexChange() {}
    };

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: props.initialIndex
        };
    }

    ///////////////////////
    // Lifecycle methods //
    ///////////////////////

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyUp, false);
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.items !== prevProps.items) {
    //         this.adjustPage(this.state.activeIndex * -1);
    //     }
    // }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
        this._prev = null;
        this._next = null;
    }

    ////////////////////
    // Event handlers //
    ////////////////////

    handleKeyUp = (e) => {
        const fromKeyboard = true;

        switch (e.key) {
            case 'ArrowLeft':
                this.goPrevious(fromKeyboard);
                break;
            case 'ArrowRight':
                this.goNext(fromKeyboard);
                break;
            case 'ArrowUp':
                break;
            case 'ArrowDown':
                break;
            default:
                break;
        }
    }

    handleCarouselButtonClick = (e) => {
        const button = e.currentTarget;
        const indexDelta = parseInt(button.getAttribute('data-delta'), 10);

        button.blur();

        this.adjustPage(indexDelta);
    };

    handleCarouselButtonTouchEnd = (e) => {
        const button = e.currentTarget;

        button.classList.add(styles.touched);

        this.flashButton(button);
    }

    handleSwipe = (direction) => {
        switch (direction) {
            case 'left':
                this.goNext();
                break;

            case 'right':
                this.goPrevious();
                break;

            default:
                break;
        }
    };

    handleTouchStart = (evt) => {
        xDown = (evt.originalEvent || evt).touches[0].clientX;
        yDown = (evt.originalEvent || evt).touches[0].clientY;
    };

    handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        const xUp = (evt.originalEvent || evt).touches[0].clientX;
        const yUp = (evt.originalEvent || evt).touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /* most significant */
            if (xDiff > 0) {
                this.handleSwipe('left');
                /* left swipe */
            }
            else {
                this.handleSwipe('right');
                /* right swipe */
            }
        }
        else if (yDiff > 0) {
            this.handleSwipe('up');
            /* up swipe */
        }
        else {
            this.handleSwipe('down');
            /* down swipe */
        }

        /* reset values */
        xDown = null;
        yDown = null;
    };

    ////////////////////
    // Helper methods //
    ////////////////////

    goNext(fromKeyboard) {
        if (this.isRightDisabled()) {
            return;
        }

        if (fromKeyboard && this._next) {
            this.flashButton(this._next);
        }

        this.adjustPage(1);
    }

    goPrevious(fromKeyboard) {
        if (this.isLeftDisabled()) {
            return;
        }

        if (fromKeyboard && this._prev) {
            this.flashButton(this._prev);
        }

        this.adjustPage(-1);
    }

    flashButton(button) {
        button.classList.add(styles.active);

        setTimeout(() => {
            window.requestAnimationFrame(() => {
                button.classList.remove(styles.active);
            });
        }, 200);
    }

    adjustPage(indexDelta) {
        this.setState((prevState) => {
            return {
                ...prevState,
                activeIndex: clamp(prevState.activeIndex + indexDelta, 0, this.props.items.length - 1)
            };
        }, () => {
            this.props.onIndexChange(this.state.activeIndex);
        });
    }

    isLeftDisabled() {
        return this.state.activeIndex === 0;
    }

    isRightDisabled() {
        const { items } = this.props;
        const { activeIndex } = this.state;

        return activeIndex >= items.length - 1;
    }

    renderItems(items = []) {
        return items.map((item, i) => {
            const klass = classnames(styles.item, {
                [styles.itemActive]: i === this.state.activeIndex
            });

            return (
                <div
                    className={klass}
                    key={i}
                >
                    {item}
                </div>
            );
        });
    }

    render() {
        const { items, className } = this.props;
        const leftDisabled = this.isLeftDisabled();
        const rightDisabled = this.isRightDisabled();
        const klass = classnames(styles.carousel, {
            [className]: className
        });

        return (
            <div className={klass}>
                <button
                    className={`${styles.left} u-flex-center`}
                    disabled={leftDisabled}
                    onClick={this.handleCarouselButtonClick}
                    onTouchEnd={this.handleCarouselButtonTouchEnd}
                    data-delta="-1"
                    aria-label="Previous image"
                    ref={(c) => this._prev = c}
                >
                    <ChevronLeftIcon />
                </button>
                <div className={styles.inner}>
                    <div
                        className={styles.items}
                        onTouchStart={this.handleTouchStart}
                        onTouchMove={this.handleTouchMove}
                    >
                        {this.renderItems(items)}
                    </div>
                </div>
                <button
                    className={`${styles.right} u-flex-center`}
                    disabled={rightDisabled}
                    onClick={this.handleCarouselButtonClick}
                    onTouchEnd={this.handleCarouselButtonTouchEnd}
                    data-delta="1"
                    aria-label="Next image"
                    ref={(c) => this._next = c}
                >
                    <ChevronRightIcon />
                </button>
            </div>
        );
    }
}
