import React from 'react';
import PropTypes from 'prop-types';

import HouseLikes from './HouseLikes';

import styles from './DetailFooter.module.scss';

function Footer({ house, itemIndex, itemCount }) {
    return (
        <footer className={styles.container}>
            <small className={styles.counter}>{itemIndex + 1} / {itemCount}</small>
            <HouseLikes
                house={house}
                tag="small"
                className={styles.likes}
                prefix="What I like: "
            />
        </footer>
    );
}

Footer.propTypes = {
    house: PropTypes.object,
    itemIndex: PropTypes.number,
    itemCount: PropTypes.number
};

export default Footer;
