import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './DetailHeader.module.scss';
import BackArrowIcon from '../images/icons/arrow-left.inline.svg';
import HouseStats from './HouseStats';

function DetailHeader({ house }) {
    return (
        <header className={styles.container}>
            <Link to="/" className={styles.arrow}>
                <BackArrowIcon />
            </Link>
            <h3 className={styles.address}>{house.address}</h3>
            <HouseStats house={house} tag="small" />
        </header>
    );
}

DetailHeader.propTypes = {
    house: PropTypes.object
};

export default DetailHeader;
